<template>
  <IAmOverlay :loading="isLoading">
    <BCard
      v-if="isRetail"
      no-body
      class="px-1 py-1"
    >
      <h4 class="font-weight-bolder">
        Vui lòng chọn đại lý bán lẻ:
      </h4>
      <SelectChildAgency
        id="agency-code"
        :agency-select.sync="agencySelect"
        style="max-width: 500px"
        :clearable="false"
        :placeholder="$t('saleReport.phAgencyCode')"
      />
    </BCard>
    <b-tabs
      v-if="employeeId"
      pills
      title-link-class="font-medium-1 font-weight-bolder mb-1"
      active
      class="justify-content-between"
    >
      <template #tabs-end>
        <div class="d-flex-center justify-content-end flex-fill">
          <BButton
            v-b-modal.modal-create-profile
            variant="info"
          >
            {{ $t('fee.create.buttonTitle') }}
          </BButton>
        </div>
      </template>

      <b-tab active>
        <template #title>
          <feather-icon
            icon="MapPinIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('fee.domesticFee') }}</span>
        </template>
        <ServiceFeeTabProfiles
          ticket-type="INLAND"
          :fee-services-data-props="feeServicesDataInland"
          :employee-id="employeeId"
          @getFeeConfigsData="getFeeConfigsData"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="NavigationIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('fee.internationalFee') }}</span>
        </template>
        <ServiceFeeTabProfiles
          ticket-type="INTERNATIONAL"
          :fee-services-data-props="feeServicesDataInternational"
          :employee-id="employeeId"
          @getFeeConfigsData="getFeeConfigsData"
        />
      </b-tab>
    </b-tabs>
    <CreateProfileModal
      :employee-id="employeeId"
      @getFeeConfigsData="getFeeConfigsData"
    />
  </IAmOverlay>
</template>

<script>
import {
  BTab, BTabs, BButton, BCard,
} from 'bootstrap-vue'
import {
  onMounted, ref, onUnmounted, computed, watch, nextTick,
} from '@vue/composition-api'

import { getUserData } from '@/api/auth/utils'
import store from '@/store'
import { apiAgencies } from '@/api'
import env from '@/libs/env'

import serviceFeeStoreModule from './serviceFeeStoreModule'
import useServiceFeeHandle from './useServiceFeeHandle'

export default {
  components: {
    BTab,
    BTabs,
    BButton,
    BCard,
    ServiceFeeTabProfiles: () => import('./ServiceFeeTabProfiles.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    CreateProfileModal: () => import('./CreateProfileModal.vue'),
    SelectChildAgency: () => import('../../retail/components/SelectChildAgency.vue'),
  },
  watch: {
    $route() {
      this.getFeeConfigsData()
    },
  },
  setup(_, { root }) {
    const SERVICE_FEE_APP_STORE_MODULE_NAME = 'app-service-fee'

    // Register module
    if (!store.hasModule(SERVICE_FEE_APP_STORE_MODULE_NAME)) store.registerModule(SERVICE_FEE_APP_STORE_MODULE_NAME, serviceFeeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SERVICE_FEE_APP_STORE_MODULE_NAME)) store.unregisterModule(SERVICE_FEE_APP_STORE_MODULE_NAME)
    })
    const {
      getEmployeeProfile,
    } = useServiceFeeHandle()

    const { isHideConfigSourceTH } = env

    const isLoading = ref(false)
    const feeServicesDataInland = ref([])
    const feeServicesDataInternational = ref([])
    const agencySelect = ref()
    const retailAgencyId = ref()
    const isRetail = computed(() => root.$route?.name === 'apps-retailConfigsServiceFee')
    const employeeId = computed(() => isRetail.value ? retailAgencyId.value : getUserData().employeeData.id)

    const distributorsList = computed(() => store.getters['globalConfig/getDistributors'])

    async function getFeeConfigsData() {
      if (employeeId.value) {
        isLoading.value = true
        const { data } = await getEmployeeProfile(employeeId.value)

        // Fake những source chưa có cấu hình phí trong profile
        data.forEach(profileItem => {
          const employeeIdInServiceFeeConfig = profileItem.serviceFeeConfigs[0]?.employee?.id || employeeId.value
          const employeeProfileId = profileItem.id
          Object.keys(distributorsList.value).forEach(key => {
            distributorsList.value[key].forEach(sourceItem => {
              if (isHideConfigSourceTH && (sourceItem === 'TH')) return
              if (profileItem.serviceFeeConfigs.find(i => i.ticketType === key.toUpperCase() && i.airline === sourceItem)) return
              profileItem.serviceFeeConfigs.push({
                airline: sourceItem,
                feeType: 'FLIGHT_ITINERARY',
                ticketType: key.toUpperCase(),
                childAmount: 0,
                adultAmount: 0,
                infantAmount: 0,
                employee: {
                  id: employeeIdInServiceFeeConfig,
                },
                employeeProfile: {
                  id: employeeProfileId,
                },
              })
            })
          })
        })
        feeServicesDataInland.value = data
        feeServicesDataInternational.value = data
        isLoading.value = false
      }
    }

    watch(distributorsList, () => {
      getFeeConfigsData()
    }, { immediate: true })

    onMounted(() => {
      store.dispatch('globalConfig/getAvailableDistributorSource')
    })

    watch(agencySelect, async val => {
      if (val?.id) {
        isLoading.value = true
        const res = await apiAgencies.getAgenciesById(val?.id)
        isLoading.value = false
        retailAgencyId.value = res.data?.employeeAdminId
        nextTick(() => {
          getFeeConfigsData()
        })
      }
    })

    return {
      isLoading,
      getFeeConfigsData,
      feeServicesDataInland,
      feeServicesDataInternational,
      employeeId,
      agencySelect,
      isRetail,
    }
  },
}
</script>
